import { Determinator } from "react-multi-language";

import Section from '../Section';
import Subsection from '../Subsection';

const Experience = function (props) {
	const title = (
		<Determinator>
			{{
				es: "Experiencia Laboral",
				en: "Working experience"
			}}
		</Determinator>
	);

	const accenture = (
		<Determinator>
			{{
				es: "Experiencia de becariado en Accenture Applied Intelligence Global",
				en: "Internship experience in Accenture Applied Intelligence Global"
			}}
		</Determinator>
	)

	const accenture_time_2022 = (
		<Determinator>
			{{
				es: "Verano de 2022",
				en: "Summer 2022"
			}}
		</Determinator>
	)
	const accenture_time_2023 = (
		<Determinator>
			{{
				es: "Febrero de 2023 - Agosto de 2023",
				en: "February 2023 - August 2023"
			}}
		</Determinator>
	)

	const work0_title = (
		<Determinator>
			{{
				es: "Agente comercial para Hidronia de piscinas de arena de Biodesign",
				en: "Salesman for Hidronia, for sand pools of Biodesign"
			}}
		</Determinator>
	);

	const work1 = (
		<Determinator>
			{{
				es: "Verano de 2019",
				en: "Summer of 2019"
			}}
		</Determinator>
	);

	const work2_title = (
		<Determinator>
			{{
				es: "Asistente de organización para las oposiciones de Celador de Asturias",
				en: "Organizational assistant for the examinations for warden of Asturias"
			}}
		</Determinator>
	);

	const work2_time = (
		<Determinator>
			{{
				es: "Marzo de 2019",
				en: "March, 2019"
			}}
		</Determinator>
	);

	const work3 = (
		<Determinator>
			{{
				es: "2018 a 2019",
				en: "2018 to 2019"
			}}
		</Determinator>
	);

	return (
		<Section title={title}>
			<ul>
				<li><Subsection title={accenture} subtitle={accenture_time_2023} /></li>
				<li><Subsection title={accenture} subtitle={accenture_time_2022} /></li>
				<li><Subsection title={work0_title} subtitle="FIDMA 2021" /></li>
				<li><Subsection title="Green Software, Gijón" subtitle={work1} /></li>
				<li><Subsection title={work2_title} subtitle={work2_time} /></li>
				<li><Subsection title="Community manager, HIDRONIA" subtitle={work3} /></li>
			</ul>
		</Section>
	);
}

export default Experience;