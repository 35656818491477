import { Determinator } from "react-multi-language";

import Section from '../Section';
import Subsection from '../Subsection';

const MyLanguages = function(props) {
	const title = (
		<Determinator>
			{{
				es: "Idiomas",
				en: "Languages"
			}}
		</Determinator>
	);

	const eng = (
		<Determinator>
			{{
				es: "Inglés",
				en: "English"
			}}
		</Determinator>
	);

	const esp = (
		<Determinator>
			{{
				es: "Español",
				en: "Spanish"
			}}
		</Determinator>
	);

	const native = (
		<Determinator>
			{{
				es: "Nativo",
				en: "Native"
			}}
		</Determinator>
	);
	
	return (
		<Section title={title} style={{ marginTop: 30 + 'px' }} dark>
			<Subsection title={eng} subtitle="C1" dark />
			<Subsection title={esp} subtitle={native} dark />
		</Section>
	);
}

export default MyLanguages;