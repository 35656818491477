import { Determinator } from "react-multi-language";

import Section from '../Section';
import Subsection from '../Subsection';


const Education = function(props) {

	const title = (
		<Determinator>
			{{
				es: "Educación",
				en: "Education"
			}}
		</Determinator>
	);

	const epi = (
		<Determinator>
			{{
				es: "Ingeniería Informática en Tecnologías de la Información - Escuela Politécnica de Ingeniería, Gijón",
				en: "Computer Engineering - Escuela Politécnica de Ingeniería, Gijón"
			}}
		</Determinator>
	);

	const ongoing = (
		<Determinator>
			{{
				es: "En curso",
				en: "Ongoing"
			}}
		</Determinator>
	);
	
	return (
		<Section title={title}>
			<Subsection title={epi} subtitle={ongoing}/>
		</Section>
	);
}

export default Education;