import React, { Component } from "react";
import { MultiLang, Determinator } from "react-multi-language";

import Section from './components/Section';
import Subsection from './components/Subsection';

import Skills from './components/sections/Skills';
import MyLanguages from './components/sections/Languages';
import Profile from './components/sections/Profile';
import Education from './components/sections/Education';
import Experience from './components/sections/Experience';

import LangSelect from "./components/LangSelector";


class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lang: props.lang || "es"
		};
	}

	changeLang = () => {
		this.setState(state => ({ lang: state.lang === "es" ? "en" : "es" }));
	};

	render() {
		return (
			<div className="flex-container">

				{/* LEFT CONTAINER */}
				<div className="flex-item left sidebar" style={{maxHeight: "100vh", overflowY: "hidden"}}>
					<Section title="Personal information" dark>
						<p>Email: <a href="mailto:margual56@gmail.com">margual56@gmail.com</a></p>
						<p>
							<Determinator>
								{{
									es: "Teléfono",
									en: "Phone"
								}}
							</Determinator>
							: +34 673371294</p>

					</Section>

					<Section title="Links" dark>
						<p style={{ fontSize: "12px" }}>
							(
							<Determinator>
								{{
									es: "Pulsa en las imágenes",
									en: "Click on the images"
								}}
							</Determinator>
							)
						</p>
						<div className="sidebar-paragraph">
							<p><a href="https://github.com/margual56" target="_blank" rel="noreferrer">
								<img src={process.env.PUBLIC_URL + "/GitHub-Logos/GitHub_Logo_White.png"} alt="GitHub logo" />
							</a></p>

							<p><a href="https://www.linkedin.com/in/marcos-guti%C3%A9rrez-alonso-84b4251a9/" target="_blank" rel="noreferrer">
								<img src={process.env.PUBLIC_URL + "/LinkedIn-Logos/LI-Logo.png"} alt="LinkedIn logo" style={{ paddingLeft: 5 + 'px', maxWidth: 100 + 'px' }} />
							</a></p>

							<p><a href="https://artstation.com/margual56" target="_blank" rel="noreferrer">
								<img src={process.env.PUBLIC_URL + "/artstation-logo/pngs/artstation-logo-white.png"} alt="ArtStation logo" style={{ maxWidth: 110 + 'px' }} />
							</a></p>
						</div>
					</Section>


					<Skills />

					<MyLanguages />

					<MultiLang lang={this.state.lang} />
				</div>

				{/* RIGHT CONTAINER */}
				<div className="flex-item right" style={{maxHeight: "100vh", overflowY: "scroll"}}>
					<LangSelect lang={this.state.lang} onClick={
						() => {
							console.info("Language change requested!");
							this.changeLang();
						}
					} />
					<div className="section" style={{ marginTop: 5 + 'px', textAlign: "center" }}>
						<div className="flex-container" style={{ height: 'fit-content' }}>
							<div className="flex-item left"
								style={{
									color: 'black',
									height: 'fit-content',
									width: 1000 + '%',
									border: 'none',
									paddingLeft: 0
								}}>
								<h1>Marcos Gutiérrez Alonso</h1>
								Software engineer with passion for the field
							</div>

							<div className="flex-item right">
								<img src={process.env.PUBLIC_URL + "/profile.jpeg"} style={{
									maxHeight: 'auto',
									maxWidth: 200 + 'px',
									borderRadius: 50 + '%'
								}} alt="Marcos' face" />
							</div>
						</div>
					</div>

					<Profile lang={this.state.lang} />
					<Education />
					<Experience />

					<Section title={
						<Determinator>
							{{
								es: "Proyectos",
								en: "Projects"
							}}
						</Determinator>
					}>
						<div className="grid">
							<Subsection title="Turing Machine 2.0">
								<p>A Turing Machine simulator and interpreter written in <b style={{color: "#e34600"}}>Rust</b></p>
								<p><a href="https://github.com/turing-marcos/turing-machine" target="_blank" rel="noreferrer">Check out the code</a> or <a href="https://turing.coldboard.net" target="_blank" rel="noreferrer">try it online</a></p>
							</Subsection>

							<Subsection title="Radio CLI">
								<p>A simple Radio for the console, with almost all radio stations from all over the world, written in <b style={{color: "#e34600"}}>Rust</b>!</p>
								<p><a href="https://github.com/margual56/radio-cli" target="_blank" rel="noreferrer">Check out the code</a> or <a href="https://github.com/margual56/radio-cli/releases/latest" target="_blank" rel="noreferrer">Download and try it</a></p>
							</Subsection>

							<Subsection title="Connect 4 online">
								<p>A simple connect 4 game, with a server and a client, written in <b style={{color: "#e34600"}}>Rust</b>!</p>
								<p><a href="https://github.com/margual56/connect4" target="_blank" rel="noreferrer">See the code</a> or <a href="https://github.com/margual56/connect4/releases" target="_blank" rel="noreferrer">Download and try it</a></p>
							</Subsection>

							<Subsection title="Renderer in Java (using Ray Marching)">
								<p><a href="https://github.com/margual56/RayMarching" target="_blank" rel="noreferrer">Check out the code</a></p>
							</Subsection>

							<Subsection title="Authentication service">
								<p>Login/Signup, easy to deploy server using Flask and a PostgreSQL encrypted database.</p><p><a href="https://github.com/margual56/SimpleAuth" target="_blank" rel="noreferrer">Check out the code</a></p>
							</Subsection>

							<Subsection title="And many more!">
								<p>Visit <a href="https://github.com/margual56" target="_blank" rel="noreferrer">my GitHub account</a> to see them all.</p>
							</Subsection>
						</div>
					</Section>

					<MultiLang lang={this.state.lang} />
				</div>
			</div >
		);
	}
}

export default App;