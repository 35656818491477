import { Determinator } from "react-multi-language";

import Section from '../Section';
import Subsection from '../Subsection';

const Skills = function(props){
	const title = (
		<Determinator>
			{{
				es: "Habilidades profesionales",
				en: "Professional Skills"
			}}
		</Determinator>
	);

	const devops = (
		<Determinator>
			{{
				es: "Server deployment y DevOps",
				en:"Server deployment and DevOps"
			}}
		</Determinator>
	);

	const lvl3 = (
		<Determinator>
			{{
				es: "Experto",
				en: "Expert"
			}}
		</Determinator>
	);

	const lvl2 = (
		<Determinator>
			{{
				es: "Avanzado",
				en: "Advanced"
			}}
		</Determinator>
	);

	const lvl1 = (
		<Determinator>
			{{
				es: "Intermedio",
				en: "Intermediate"
			}}
		</Determinator>
	);

	const lvl0 = (
		<Determinator>
			{{
				es: "Principiante",
				en: "Beginner"
			}}
		</Determinator>
	);
	
	return (
		<Section title={title} dark>
			<Subsection title="C, C++, C#, Rust" subtitle={lvl2} dark/>

			<Subsection title="SQL, MySQL, PostgreSQL" subtitle={lvl2} dark/>

			<Subsection title="Java, Python" subtitle={lvl3} dark/>

			<Subsection title="HTML, CSS" subtitle={lvl3} dark/>

			<Subsection title="JavaScript, jQuery" subtitle={lvl2} dark/>

			<Subsection title={<div>Front End frameworks<br/>(Angular, React, egui/eframe (Rust))</div>} subtitle={lvl1} dark/>

			<Subsection title="Zig, Go" subtitle={lvl1} dark/>

			<Subsection title={devops} subtitle={lvl2} text="Flask, Nginx, Apache, etc" dark/>
		</Section>
	);
}

export default Skills;