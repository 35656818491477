import { Determinator } from "react-multi-language";

import Section from '../Section';

const Profile = function (props) {
	const title = (
		<Determinator>
			{{
				es: "Perfil",
				en: "Profile"
			}}
		</Determinator>
	);

	return (
		<Section title={title}>
			<Determinator>
				{{
					es: "Soy un ingeniero de software experimentado e independiente, con una amplia variedad de proyectos de código abierto y una cierta experiencia en el campo empresarial.",
					en: "I am an experienced and independent software engineer, with a wide range of open-source projects and some experience in the corporate field."
				}}
			</Determinator><br />
			<Determinator>
				{{
					es: "Me caracterizo por ser un trabajador dedicado y comprometido con la calidad de mi trabajo.",
					en: "I am known for being a dedicated worker and committed to the quality of my work."
				}}
			</Determinator>
		</Section>
	);
}

export default Profile;