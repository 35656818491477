import React from 'react'

class Subsection extends React.Component {
    constructor(props){
        super(props);

        this.title = props.title;
        this.subtitle = props.subtitle;
        this.text = props.text;

        this.dark = props.dark;
    }

    render(){
        return (
            <div className="section-item">
                <div className={"title" + (this.dark?" dark":"")}>{this.title}</div>
                <div className={"subtitle" + (this.dark?" dark":"")}>{this.subtitle}</div>
                {this.props.text ? (<div className="text">{this.text}</div>):(null)}
                <div className="text">{this.props.children}</div>
            </div>
        );
    }
}

export default Subsection;