import React from 'react';
import ReactDOM from 'react-dom';

import App from './main';

import './styles/style.scss';
import './styles/secondaryStyle.scss';

ReactDOM.render(
<React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

