import React, { Component } from "react";
import { Determinator } from "react-multi-language";

class LangSelect extends Component {
	constructor(props) {
        super(props);

		this.state = {
			lang: props.lang,
			onClick: props.onClick
		};
	}

    changeLang = () => {
        this.setState(state => ({ lang: state.lang === "es" ? "en" : "es" }));
    };

	render() {
		const lang = (
			<Determinator>
				{{
					es: "Idioma: ",
					en: "Language: "
				}}
			</Determinator>
		);

		const esp = (
				<Determinator>
					{{
						es: "Español",
						en: "Spanish"
					}}
				</Determinator>
		);

		const eng = (
				<Determinator>
					{{
						es: "Inglés",
						en: "English"
					}}
				</Determinator>
		);

		if(this.state.lang === "es") {
			return (
				<button className="language-selector" onClick={() => {
					this.state.onClick();
					this.changeLang()
				}}>
					{lang}<b>{esp}</b>&#9553;{eng}
				</button>
			);
		}else{
			return (
				<button className="language-selector" onClick={() => {
					this.state.onClick();
					this.changeLang()
				}}>
					{lang}{esp}&#9553;<b>{eng}</b>
				</button>
			);
		}
	}
}

export default LangSelect;