import React from 'react'

class Section extends React.Component {
    constructor(props) {
        super(props);

        this.title = props.title;
        this.text = props.text;

        this.classes = props.className;

        this.dark = props.dark;
    }

    render(){
        return (
            <div className="section">
                <h3 className={"title" + (this.dark?" dark":"")}>{this.title}</h3>
                {this.props.children}
            </div>
        );
    }
}

export default Section;